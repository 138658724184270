<template>
    <div style="margin-top: 10px;">
        
        <div class="w3-row w3-white w3-card-2">
            <header class="w3-container">
                <h4 style="text-transform: uppercase;">{{judul}}</h4>
            </header>
            <div v-if="showHeader" class="w3-margin w3-padding w3-card-4 w3-theme-d4">
                <slot name="thead"/>
            </div>
            <div class="w3-margin w3-card-4">
                <div class="w3-bar w3-theme-d4">
                    <button v-on:click="posisitab='a'" :class="posisitab=='a'?'w3-white':''" class="w3-bar-item w3-button tablink w3-border-right" ><i class="fa fa-calendar"></i> Per Tanggal</button>
                    <button v-on:click="posisitab='b'" :class="posisitab=='b'?'w3-white':''" class="w3-bar-item w3-button tablink w3-border-right" ><i class="fa fa-calendar"></i> Per Bulan</button>
                    <button v-on:click="posisitab='c'" :class="posisitab=='c'?'w3-white':''" class="w3-bar-item w3-button tablink w3-border-right" ><i class="fa fa-calendar"></i> Per Tahun</button>
                </div>
                <div v-show="posisitab=='a'" class="w3-container">
                    <div class="w3-row w3-margin">
                        <div class="w3-col l1">
                            Tanggal
                        </div>
                        <div class="w3-col l3 m2 s12" style="position: relative;padding-right: 43px;">
                            <input type="text" class="w3-input w3-small w3-border-left w3-border-top" v-model="tgl1" autofocus v-on:click="showdate.tgl1=true" >
                            <transition name="calendar-fade">
                                <date-picker min="2019-01-01" @close="showdate.tgl1 = false" v-if="showdate.tgl1" v-model="tgl1"></date-picker>
                            </transition>
                            <button v-on:click="showdate.tgl1=true" type="button" class="w3-btn w3-small w3-theme-d3" style="position: absolute; top: 0px; right: 0px;"><i class="fa fa-calendar"></i></button>
                        </div>
                        <div class="w3-col l1 w3-center" style="padding-top:5px;">s/d</div>
                        <div class="w3-col l3 m2 s12" style="position: relative;padding-right: 43px;">
                            <input type="text" class="w3-input w3-small w3-border-left w3-border-top" v-model="tgl2" autofocus v-on:click="showdate.tgl2=true" >
                            <transition name="calendar-fade">
                                <date-picker min="2019-01-01" @close="showdate.tgl2 = false" v-if="showdate.tgl2" v-model="tgl2"></date-picker>
                            </transition>
                            <button v-on:click="showdate.tgl2=true" type="button" class="w3-btn w3-small w3-theme-d3 " style="position: absolute; top: 0px; right: 0px;"><i class="fa fa-calendar"></i></button>
                        </div>
                    </div>
                </div>
                <div v-show="posisitab=='b'" class="w3-container">
                    <div class="w3-row w3-margin">
                        <div class="w3-col l1" style="padding-top:5px;">
                            Bulan
                        </div>
                        <div class="w3-col l3 m2 s12" style="position: relative;padding-right: 43px;">
                            <select class="w3-select w3-small" v-model="bln">
                                <option value="1">Januari</option>
                                <option value="2">Februari</option>
                                <option value="3">Maret</option>
                                <option value="4">April</option>
                                <option value="5">Mei</option>
                                <option value="6">Juni</option>
                                <option value="7">Juli</option>
                                <option value="8">Agustus</option>
                                <option value="9">September</option>
                                <option value="10">Oktober</option>
                                <option value="11">November</option>
                                <option value="12">Desember</option>
                            </select>
                        </div>
                        <div class="w3-col l1 w3-center" style="padding-top:5px;">Tahun</div>
                        <div class="w3-col l2 m2 s12" style="position: relative;padding-right: 43px;">
                            <select class="w3-select w3-small" v-model="thn">
                                <option v-for="(dtl, index) in tahuns" :value="dtl.tahun" :key="index">{{dtl.tahun}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div v-show="posisitab=='c'" class="w3-container">
                    <div class="w3-row w3-margin">
                        <div class="w3-col l1 w3-center" style="padding-top:5px;">Tahun</div>
                        <div class="w3-col l2 m2 s12" style="position: relative;padding-right: 43px;">
                            <select class="w3-select w3-small" v-model="thn">
                                <option v-for="(dtl, index) in tahuns" :value="dtl.tahun" :key="index">{{dtl.tahun}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="w3-theme-d4" >
                    <button @click="printData" type="button" class="w3-btn w3-theme-l5 w3-small" style="margin:5px;"><i class="fa fa-file-text" aria-hidden="true"></i> Preview</button>
                    <button @click="exportData" type="button" class="w3-btn w3-theme-l5 w3-small" style="margin:5px;"><i class="fa fa-file-excel-o" aria-hidden="true"></i> Export XLS</button>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
/* eslint-disable */
import moment from 'moment';

export default {
    head: {
        title: {
          inner: 'DLaporan'
        },
    },
    name: 'DLaporan',
    components: {
        
    },
    data() {
        return {
            tgl1 : moment(String(new Date())).format('YYYY-MM-DD'),
            tgl2 : moment(String(new Date())).format('YYYY-MM-DD'),
            bln : moment(String(new Date())).format('M'),
            thn : moment(String(new Date())).format('YYYY'),
            showdate : {tgl1 : false, tgl2 : false},
            tahuns : [],
            posisitab : 'a'
        }
    },
    props: {
        showHeader: {
            type: Boolean,
            default: false
        },
        setPrint: {
            type: Function,
            required: true,
            default: () => { }
        },
        judul : {
            type : String,
            default : 'Laporan'
        },
        tab: {
            type: String,
            required: false,
            default : 'a'
        },
        showTgl: {
            type: Boolean,
            default: true
        },
        showBln: {
            type: Boolean,
            default: true
        },
        showThn: {
            type: Boolean,
            default: true
        }
    },
    methods : {
        printData : function(){
            this.setPrint(this.posisitab ,this.tgl1,this.tgl2,this.bln,this.thn, true);
        },
        exportData : function(){
            this.setPrint(this.posisitab,this.tgl1,this.tgl2,this.bln,this.thn, false);
        }
    },
    computed :{
        
    },
    mounted(){
       for (let index = 1990; index <= 2050; index++) {
           this.tahuns.push({
               'tahun' : index
           });
       }
       this.posisitab = this.tab;
    }
};
</script>