<template>
    <div class="w3-theme-l1">
        <div class="w3-container">
            <DLaporan judul="Laporan Antrian" :setPrint="printLaporan"/>
            <header-report id="headerReport" style="display:none"/>
            <footer-report id="footherReport" style="display:none"/>
        </div>

        <!-- Report -->
        <!-- Cetak data -->
        <!-- Cetak data -->
        <div id="id01" class="w3-modal">
            <vue-draggable-resizable :y="0" :drag-handle="'.drag'" style="width:100%;">
                <div class="w3-modal-content w3-animate-zoom w3-card-4" style="max-width:98%;">
                    <header class="w3-container w3-theme-d3 drag"> 
                        <span onclick="document.getElementById('id01').style.display='none'" 
                        class="w3-button w3-display-topright">&times;</span>
                        <h6><i class="fa fa-newspaper-o"></i> Preview</h6>
                    </header>
                    <div class="w3-white w3-responsive" style="height:400px;">
                        <div id="cetakDetail" style="left:10px;top:10px;margin-left:10px;width:100%!important;">
                            <header-report />
                            <div align="center">
                                <h4>
                                    LAPORAN ANTRIAN
                                    <div>{{judul}}</div>
                                </h4>
                            </div>
                            <table style="width:100%;border-collapse:collapse;" class="detail">
                                <thead>
                                    <tr>
                                        <th width="50px;">No</th>
                                        <th>Pelayanan</th>
                                        <th width="80px;">Periode</th>
                                        <th width="80px;">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    
									<tr v-for="(dtl, index) in recReports" :key="index">
										<td style="text-align:center;">{{index+1}}</td>
										<td>{{dtl.pelayanan_name}}</td>
										<td style="text-align:center;">{{dtl.periode}}</td>
										<td style="text-align:center;">{{dtl.total}}</td>
									</tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colspan="3" style="text-align:right;">Total</td>
                                        <td style="text-align:center;">{{total | toRp}}</td>
                                    </tr>
                                </tfoot>
                            </table>
                            <footer>
                                <footer-report />
                            </footer>
                        </div>
                    </div>
                    <footer class="w3-container w3-theme-l1">
                        <h6 class="w3-right">
                            <button v-on:click="printReport" type="button" class="w3-btn w3-small w3-theme-d5" style="margin-right:5px;"><i class="fa fa-print"></i> Print</button>
                            <button onclick="document.getElementById('id01').style.display='none'" class="w3-btn w3-small w3-red"><i class="fa fa-close"></i> Close</button>
                        </h6>
                    </footer>
                </div>
            </vue-draggable-resizable>
        </div>
        <!-- Report -->
    </div>
</template>
<script lang="js">
/* eslint-disable */
import DLaporan from "@/components/DLaporan.vue";
import HeaderReport from "@/components/HeaderReport.vue";
import FooterReport from "@/components/FooterReport.vue";

export default {
    head: {
        title: {
            inner: "Laporan Pembelian",
        },
    },
    name: "LapPembelian",
    components: {DLaporan, HeaderReport, FooterReport},
    data() {
        return {
            screenWidth: 0,
            screenHeight: 0,
            judul : '',
            recReports : [],
            total : 0
        };
    },
    methods: {
        printLaporan : async function(tab, tgl1, tgl2, bln, thn, print){
            let self = this;
            self.total = 0;
            self.recReports = [];
            self.showLoading("Proses load data ..");
            switch (tab) {
                case 'a':
                    self.judul = `Periode Tanggal ${tgl1} s/d ${tgl1}`;
                    break;
                case 'b':
                    self.judul = `Periode Bulan ${bln} Tahun ${thn}`;
                    break;
                case 'c':
                    self.judul = `Periode Tahun ${thn}`;
                    break;
            }
            let vparams = {'operator' : '','kondisi' : '' ,'field': '' ,'fieldvalue': '' ,'limit': 50,'offset' : 0,'order' : ''};
            switch (tab) {
                case 'a':
                        vparams = {
							'operator' : 'AND',
							'kondisi' : '>=;<=' ,
							'field': 'antrian_date;antrian_date' ,
							'fieldvalue': `'${tgl1}';'${tgl2}'` ,
							'limit': 1000,
							'offset' : 0,
							'fieldselect': "pelayanan.pelayanan_code, pelayanan.pelayanan_name, count(*) as total, to_char(antrian_date ,'dd-mm-yyyy') as periode",
							'group': "pelayanan.pelayanan_code, pelayanan.pelayanan_name, antrian_date",
							'order': "antrian_date, pelayanan.pelayanan_name, pelayanan.pelayanan_code"
						};
                    break;
                case 'b':
                        vparams = {
							'operator' : 'AND',
							'kondisi' : '=;=' ,
							'field': `date_part('MONTH',antrian_date);date_part('YEAR',antrian_date)` ,
							'fieldvalue': `${bln};${thn}` ,
							'limit': 1000,
							'offset' : 0,
							'fieldselect': "pelayanan.pelayanan_code, pelayanan.pelayanan_name, count(*) as total, to_char(antrian_date ,'dd-mm-yyyy') as periode",
							'group': "pelayanan.pelayanan_code, pelayanan.pelayanan_name, antrian_date",
							'order': "antrian_date, pelayanan.pelayanan_name, pelayanan.pelayanan_code"
						};
                    break;
                case 'c':
                        vparams = {
							'operator' : 'AND',
							'kondisi' : '=' ,
							'field': `date_part('YEAR',antrian_date)` ,
							'fieldvalue': `${thn}` ,
							'limit': 1000,
							'offset' : 0,
							'fieldselect': "pelayanan.pelayanan_code, pelayanan.pelayanan_name, count(*) as total, to_char(antrian_date ,'MM yyyy') as periode",
							'group': "pelayanan.pelayanan_code, pelayanan.pelayanan_name, periode",
							'order': "periode asc"
						};
            };
			
            self.apiPost('/antrian/antrian/search',vparams, function(res){
                if(res.success){
                    self.showLoading("Proses preview ..");
					res.rec.map(function(val){
						self.total = self.total + parseInt(val['total']) ;
					});
                    self.$forceUpdate();
                    setTimeout(() => {
                        self.recReports = res.rec;
                        setTimeout(() => {
                            self.hideLoading();
                            let vhtml = document.getElementById('cetakDetail').innerHTML;
                            if(print){
								self.printHtml(vhtml,'A4','portrait','5px 25px 5px 25px');
                                // document.getElementById("id01").style.display = "block";
                            }else{
                                self.downloadFile('antrian.xls',vhtml,'data:text/xls');
                            }
                        }, 1000);
                    },1000);
                }
            });
        },
        printReport(){
            let vhtml = document.getElementById('cetakDetail').innerHTML;
            this.printHtml(vhtml,'A4','portrait','5px 25px 5px 25px');
        }
        
    },
    filters: {
        toRp(value) {
            return `${Intl.NumberFormat("de-DE").format(value)}`;
        },
    },
    watch: {
        '$route': function () {
            // this.rec.username = localStorage.username;
            // this.rec.pass = localStorage.de;
            // this.setLogin(1);
        }
    },
    mounted() {
        try {
            client.connect();                
        } catch (error) {
            
        }
        this.screenHeight = window.innerHeight ;
        this.screenWidth = window.innerWidth ;
        // this.loadData();
    },
}
</script>